body.light .content-wrapper h1, body.light .content-wrapper h2 {
  color: rgb(0, 105, 137);
}

body.light .main-list h2 {
  color: dimgray;
}

body.light .main-list h2:before {
  border-color: rgb(0, 105, 137);
}

body.light #checklist {
  --text: dimgray;
  --check: rgb(0, 105, 137);
  --disabled: #C3C8DE;
}