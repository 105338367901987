raga-photo-gallery {
  display: inline-block;
  width: 100vw;
}
.bg-hidden {
  top: -100%;
  opacity: 0;
}

.bg-visible {
  top: 0;
  opacity: 1;
}
.content-wrapper {
  width: 100%;
  padding: 1em 1%;
  padding-top: 35px;
  font-size: calc(2em * var(--expansion-factor));
  display: table;
}

.content-wrapper > * {
  position: relative;
  display: table-cell !important;
}

.content-wrapper p {
  margin: 12px 0;
  padding-right: 5%;
  text-align: justify;
}

.content-wrapper h1, .content-wrapper h2 {
  text-align: center;
  letter-spacing: 1px;
  margin: 0;
  color: rgb(220, 120, 0);
}
.content-wrapper h2 {
  font-size: 2em;
}

.content-wrapper .text-shadow {
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
      1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.content-wrapper .text-overlay{
  line-height: 1;
	display: inline-block;
	position: relative;
	font-style: normal;
	font-size: 4em;
	line-height: 1em;
	letter-spacing: 0;
	text-transform: none;
}

.content-wrapper .ing {
  width: 30%;
  display: inline-block;
  vertical-align: top;
}
.content-wrapper .descr {
  width: 65%;
  padding-left: 5%;
  display: inline-block;
  vertical-align: top;
}
#checklist {
  --background: transparent;
  --text: #fff;
  --check: #DC7800;
  --disabled: #C3C8DE;
  --width: 100%;
  --height: fit-content;
  --border-radius: 10px;
  background: var(--background);
  width: var(--width);
  height: var(--height);
  position: relative;
  padding: 30px 20px;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  line-height: 1.5;
}
#checklist label {
  color: var(--text);
  position: relative;
  cursor: pointer;
  display: grid;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: color 0.3s ease;
}
#checklist label::before, #checklist label::after {
  content: "";
  position: absolute;
}
#checklist label::before {
  height: 2px;
  width: 8px;
  left: -27px;
  background: var(--check);
  border-radius: 2px;
  transition: background 0.3s ease;
}
#checklist label:after {
  height: 4px;
  width: 4px;
  top: 8px;
  left: -25px;
  border-radius: 50%;
}
#checklist input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  height: 15px;
  width: 15px;
  outline: none;
  border: 0;
  margin: 0 15px 0 0;
  cursor: pointer;
  background: var(--background);
  display: grid;
  align-items: center;
}
#checklist input[type=checkbox]::before, #checklist input[type=checkbox]::after {
  content: "";
  position: absolute;
  height: 2px;
  top: auto;
  background: var(--check);
  border-radius: 2px;
}
#checklist input[type=checkbox]::before {
  width: 0px;
  right: 60%;
  transform-origin: right bottom;
}
#checklist input[type=checkbox]::after {
  width: 0px;
  left: 40%;
  transform-origin: left bottom;
}
#checklist input[type=checkbox]:checked::before {
  -webkit-animation: check-01 0.4s ease forwards;
          animation: check-01 0.4s ease forwards;
}
#checklist input[type=checkbox]:checked::after {
  -webkit-animation: check-02 0.4s ease forwards;
          animation: check-02 0.4s ease forwards;
}
#checklist input[type=checkbox]:checked + label {
  color: var(--disabled);
  -webkit-animation: move 0.3s ease 0.1s forwards;
          animation: move 0.3s ease 0.1s forwards;
}
#checklist input[type=checkbox]:checked + label::before {
  background: var(--disabled);
  -webkit-animation: slice 0.4s ease forwards;
          animation: slice 0.4s ease forwards;
}
#checklist input[type=checkbox]:checked + label::after {
  -webkit-animation: firework 0.5s ease forwards 0.1s;
          animation: firework 0.5s ease forwards 0.1s;
}


.main-list > * {
  width: 100%;
  padding: 15px 30px;
}
.main-list > *:last-child, .main-list > *:first-child {
  padding-bottom: 30px;
}

.main-list h1 {
  font-weight: bold;
  border-top: 2px solid rgba(255, 255, 255, 0.533);
}

.main-list {
  margin: 0;
  padding: 0;
}

.main-list h2 {
  position: relative;
  color: #fff;
  text-align: left;
  margin-left: 3rem;
}

.main-list h2:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 20px;
    width: 7px;
    height: 14px;
    border-width: 0 3px 9px 0;
    border-style: solid;
    border-color: rgb(220, 120, 0);
    transform-origin: bottom left;
    transform: rotate(45deg);
}



@-webkit-keyframes move {
  50% {
    padding-left: 8px;
    padding-right: 0px;
  }
  100% {
    padding-right: 4px;
  }
}

@keyframes move {
  50% {
    padding-left: 8px;
    padding-right: 0px;
  }
  100% {
    padding-right: 4px;
  }
}
@-webkit-keyframes slice {
  60% {
    width: 100%;
    left: 4px;
  }
  100% {
    width: 100%;
    left: -2px;
    padding-left: 0;
  }
}
@keyframes slice {
  60% {
    width: 100%;
    left: 4px;
  }
  100% {
    width: 100%;
    left: -2px;
    padding-left: 0;
  }
}
@-webkit-keyframes check-01 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(45deg);
  }
  100% {
    width: 5px;
    top: 8px;
    transform: rotate(45deg);
  }
}
@keyframes check-01 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(45deg);
  }
  100% {
    width: 5px;
    top: 8px;
    transform: rotate(45deg);
  }
}
@-webkit-keyframes check-02 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(-45deg);
  }
  100% {
    width: 10px;
    top: 8px;
    transform: rotate(-45deg);
  }
}
@keyframes check-02 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(-45deg);
  }
  100% {
    width: 10px;
    top: 8px;
    transform: rotate(-45deg);
  }
}
@-webkit-keyframes firework {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check);
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    box-shadow: 0 -15px 0 0px var(--check), 14px -8px 0 0px var(--check), 14px 8px 0 0px var(--check), 0 15px 0 0px var(--check), -14px 8px 0 0px var(--check), -14px -8px 0 0px var(--check);
  }
}
@keyframes firework {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check);
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    box-shadow: 0 -15px 0 0px var(--check), 14px -8px 0 0px var(--check), 14px 8px 0 0px var(--check), 0 15px 0 0px var(--check), -14px 8px 0 0px var(--check), -14px -8px 0 0px var(--check);
  }
}

.detail-container-media {
  background-color: #242629;
  background-position: 50% 50%;
  background-size: cover;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 40%;
}
.detail-container {
  float: right;
  position: relative;
  width: 60%;
  height: 100vw;
  padding-top: 80px;
}

@media only screen and (max-width: 1000px) {
  .content-wrapper {
    width: 95%;
    padding: 1em 2.5%;
  }
  .content-wrapper .ing {
    display: block !important;
    width: 100%;
  }
  .content-wrapper .descr {
    padding: 0px;
    display: block !important;
    width: 100%;
  }
  .content-wrapper .descr > * {
    padding-left: 5%;
  }
  .content-wrapper .text-overlay{
    font-size: 2em;
  }
  .main-list {
    display: none !important;
  }
  .main-list > div > a{
    font-size: 1em;
  }
  .detail-container-media {
    position: relative;
    top: 0;
    width: 100%;
  }
  .detail-container {
    width: 100%;
    padding-top: 0;
  }
}
