
main {
  padding: 0 40px 0;
}
.explosion {
  position: absolute;
  width: 400px;
  height: 400px;
  pointer-events: none;
}
.explosion .particle {
  position: absolute;
  width: calc(10px * var(--expansion-factor));
  height: calc(10px * var(--expansion-factor));
  border-radius: 50%;
  animation: pop 1s reverse forwards;
}
.egg {
  background-size: cover;
  width: calc(50px * var(--expansion-factor));
  height: calc(50px * var(--expansion-factor));
  position: absolute;
  top: 60px;
  left: 80px;
  z-index: 4;
  cursor: pointer;
}
@keyframes pop {
  from {
    opacity: 0;
  }
  to {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
}
