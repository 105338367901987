.root-normalize-container {
  font-size: 20px;
  background-color: var(--primary-color);
  margin: 0px 10px 20px 10px;
  padding: 0 10px;
  border-radius: 14px;
  left: -110vw;
  animation-duration: 1s;
  position: absolute;
  width: calc(100vw - 60px);
  color: var(--text-color);
}
.root-normalize-container.swype-right {
  left: 0vw;
  animation-name: swypeRight;
}
.root-normalize-container.swype-left {
  animation-name: swypeLeft;
}
.root-normalize-container label{
  margin: 10px 20px;
  display: inline-block;
}
.root-normalize-container .input-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  border-color: transparent;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding-left: 20px;
}
.root-normalize-container .input-container:has(input:focused) {
  border-color: var(--accent-primary-color);
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-left-width: 10px;
}
.root-normalize-container .input-container:has(input:focus) {
  border-color: var(--accent-primary-color);
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-left-width: 10px;
}
.root-normalize-container .input-container:has(input:focus-visible) {
  border-color: var(--accent-primary-color);
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-left-width: 10px;
}
.root-normalize-container .input-container.input-error {
  border-color: var(--error-color) !important;
  box-shadow: 0 0 0 1px var(--error-color) !important;
}
.root-normalize-container input[type="text"]:focus, .root-normalize-container input[type="text"]:focus-visible {
  border: none;
  outline: none;
}
.root-normalize-container input[type="text"] {
	border-radius: 4px;
	display: inline-block;
	position: relative;
	width: 100%;
  line-height: 1;
	border: none;
  font-size: 20px;
  margin: auto;
  padding: 6px 0px;
}

.root-normalize-container .single-select {
	color: black;
}
.btn-normalize {
  width:100%;
  margin: auto;
  border-color: var(--accent-text-color);
  border-style: solid;
  border-width: 2px;
  color: var(--accent-text-color);
  background-color: var(--accent-primary-color);
  text-align: center;
}
.btn-normalize:hover{
  background-color: var(--accent-text-color);
  border-color: var(--accent-primary-color);
  color: var(--accent-primary-color);
}
.btn-action {
  width: unset;
  float: right;
}
.ingr-list-action {
  font-size: 20px;
}

.root-normalize-container .tag-cloud {
  max-height: calc(100vh - 550px * var(--expansion-factor));
}

.tag-cloud-filter {
  padding: 6px !important;
  width: unset !important;
}
div.tag-cloud-filter {
  width: calc(100% - 188px) !important;
  display: inline-block;
}
.new-tag {
  border-radius: 4px 0 0 4px !important;
}
.btn-new-tag {
  width: 50px !important;
  height: 35.5px;
  float: none;
  font-size: 11px;
  border-radius: 0px 4px 4px 0px;
  top: -3px !important;
  border-left-color: #FFF;
  height: unset !important;
}
.btn-new-tag:hover {
  transform: unset;
}

.root-normalize-container .css-t3ipsp-control {
  box-shadow: 0 0 0 1px var(--accent-primary-color);
}

.root-normalize-container .css-t3ipsp-control:hover {
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-color: var(--accent-primary-color);
}
.root-normalize-container .css-d7l1ni-option {
  background-color: var(--accent-secondary-color);
}

.root-normalize-container .css-tr4s17-option, .root-normalize-container .css-d7l1ni-option:active {
  background-color: var(--accent-primary-color);
  color: var(--accent-text-color);
}
@keyframes swypeRight {
  0% {
    left: 110vw;
  }
  100% {
    left: 0vw;
  }
}

@keyframes swypeLeft {
  0% {
    left: 0vw;
  }
  100% {
    left: -110vw;
  }
}
