.dropdown-item {
  height: 100%;
  animation-name: hideDropdown;
  animation-duration: 1s;
  overflow: hidden;
  margin: 10px 20px;
  border: 1px solid var(--accent-primary-color);
  border-radius: 4px 4px 0 0;
  max-height: 0px;
  display:block;
  padding:0;
  width: calc(100% - 40px);
}
.dropdown-active {
  animation-name: showDropdown;
  border-top-width: 10px;
  max-height: 3500px;
  display:table;
}
.dropdown-item-content{
  padding: 10px 20px;
}
.dropdown-item-title {
  font-size: 35px;
  margin: 10px 20px;
  min-height: 55px;
}
.dropdown-item-title i{
  padding-right: 15px;
}
.dropdown-item-header {
    background-color: var(--accent-primary-color);
    font-size: 25px;
    font-weight: bold;
    padding: 0 10px 10px 10px;
    color: var(--accent-text-color);
}
.dropdown-item-description {
  width: 100%;
  min-height: 200px;
  border: 1px solid var(--accent-primary-color);
  border-top-width: 10px;
  background-color: #fffc;
  border-radius: 4px 4px 0 0;
  animation-duration: 2s;
  font-size: 20px;
}

.dropdown-item p{
  margin-block-start: 0em;
  margin-block-end: 0.8em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-indent: 0.5em;
}

.dropdown-item-description:focus, .dropdown-item-description:focus-visible {
  border: 1px solid var(--accent-primary-color);
  border-top-width: 10px;
  background-color: #ffff;
  outline: none;
  animation-name: focusTextarea;
}
.dropdown-item-ingredient {
  margin-block-start: 20px;
  margin-block-end: 0px;
}

.dropdown-item-cell {
  display:table-cell;
  border-right: 1px solid var(--accent-primary-color)
}
.dropdown-item-cell:last-of-type {
  border-right: none;
}

@keyframes focusTextarea {
  from {
    background-color: #fffc;
  }
  to {
    background-color: #ffff;
  }
}
@keyframes showDropdown {
  from {
    max-height: 0px;
    border-top-width: 1px;
    display: block;
  }
  to {
    max-height: 3500px;
    border-top-width: 10px;
    display: table;
  }
}
@keyframes hideDropdown {
  from {
    max-height: 3500px;
    border-top-width: 10px;
  }
  to {
    max-height: 0px;
    border-top-width: 1px;
  }
}
.root-normalize-container span.tag-cloud-tag {
  background-color: var(--secondary-color);
  border-color: #fffa;
  margin-top: 5px !important;
}
#tagsContainer span.tag-cloud-tag {
  cursor: pointer;
}
.root-normalize-container span.tag-cloud-tag:hover {
  text-decoration: none !important;
}
span.tag-cloud-tag:before {
  content: "#";
}
@media only screen and (max-width: 1000px) {
  .dropdown-item-cell {
    display: block;
    width: 100% !important;
  }
  .dropdown-item {
    display: block;
  }
}