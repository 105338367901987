@media only screen and (max-width: 1000px) {
  nav {
    position: static;
  }
  /* Appearance */
  .links {
    position: static !important;
  }
}
nav {
  position: fixed;
  z-index: 1001;
}
/* Appearance */
.links {
  font-size: calc(2em  * var(--expansion-factor));
  font-weight: calc(300 * var(--expansion-factor));
}
.links > a {
  color: #fffb;
  padding: .75em;
  text-align: center;
  text-decoration: none;
  transition: all .5s;
}
.links > a:hover {
  background: #ffffff06;
  color: #fff;
}
.links > .line {
  background: #fff;
  height: calc(2px * var(--expansion-factor));
  pointer-events: none;
}

/* The Magic */
.links {
  display: grid;
  grid-template-columns: repeat(var(--items), 1fr);
  position: fixed;
  top: 0;
  left: 0;
}
.links > .line {
  opacity: 0;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  left: var(--left, calc(100% / var(--items) * (var(--index) - 1)));
  width: var(--width, calc(100% / var(--items)));
  --index: 0;
}
.links > a:hover ~ .line {
  opacity: 1;
}

.links > a:nth-of-type(1):hover ~ .line { --index: 1; }
.links > a:nth-of-type(2):hover ~ .line { --index: 2; }
.links > a:nth-of-type(3):hover ~ .line { --index: 3; }
.links > a:nth-of-type(4):hover ~ .line { --index: 4; }
.links > a:nth-of-type(5):hover ~ .line { --index: 5; }
.links > a:nth-of-type(6):hover ~ .line { --index: 6; }
.links > a:nth-of-type(7):hover ~ .line { --index: 7; }
.links > a:nth-of-type(8):hover ~ .line { --index: 8; }
.links > a:nth-of-type(9):hover ~ .line { --index: 9; }
.links > a:nth-of-type(10):hover ~ .line { --index: 10; }
.links > a:last-of-type:hover ~ .line { --index: var(--items); }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
