.btn-share i {
  opacity: 0;
  font-size: calc(22px * var(--expansion-factor));
  color: #EEEEED;
  will-change: transform;
  -webkit-transform: scale(.1);
          transform: scale(.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}

.btn-share {
  position: absolute;
  bottom: 10px;
  left: 12.8%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  cursor: default;
  width: calc(150px * var(--expansion-factor));
  height: calc(42px * var(--expansion-factor));
  background: #538844;
  border-radius: calc(5px * var(--expansion-factor));
  padding: 0 18px;
  will-change: transform;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.btn-share:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(calc(1.1 * var(--expansion-factor)));
          transform: scale(calc(1.1 * var(--expansion-factor)));
  transform: translateY(-6px);
  background: #2a8812;
}

.btn-share span {
  position: absolute;
  z-index: 99;
  width: calc(240px * var(--expansion-factor));
  height: calc(72px * var(--expansion-factor));
  border-radius: calc(5px * var(--expansion-factor));
  font-family: "Open Sans", Avenir, "Segoe UI", Arial, sans-serif;
  font-size: calc(20px * var(--expansion-factor));
  text-align: center;
  line-height: calc(70px * var(--expansion-factor));
  letter-spacing: calc(2px * var(--expansion-factor));
  color: #EEEEED;
  background-color: #333;
  padding: 0 18px;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

.btn-share .share-ico {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: calc(240px * var(--expansion-factor));
  height: calc(64px * var(--expansion-factor));
  border-radius: calc(80px * var(--expansion-factor));
}

.btn-share .share-ico i:nth-of-type(1) {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}

.btn-share .share-ico i:nth-of-type(2) {
  -webkit-transition-delay: .9s;
          transition-delay: .9s;
}

.btn-share .share-ico i:nth-of-type(3) {
  -webkit-transition-delay: .7s;
          transition-delay: .7s;
}

.btn-share .share-ico i:nth-of-type(4) {
  -webkit-transition-delay: .4s;
          transition-delay: .4s;
}

.btn-share:hover span {
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
  -webkit-transform: translateX(calc(-280px * var(--expansion-factor)));
          transform: translateX(calc(-280px * var(--expansion-factor)))
}

.btn-share:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
