@media only screen and (max-width: 1000px) {
  .root-normalize-container {
    touch-action: pan-x pan-y;
    font-size: 30px;
  }
  .root-normalize-container input{
    font-size: 20px;
  }
  .btn-normalize{
    height: 150px;
  }
  a.btn-normalize{
    box-sizing: border-box;
    padding: 45px 0;
  }
  .btn-action {
    width: unset;
    float: right;
    top:-5px;
    height: unset;
  }
  .ingr-list-action {
    top: 10px;
  }
}