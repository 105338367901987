:root {
  /* Base font size */
  font-size: 10px;

  /* Heading height variable*/
  --heading-height: 20em;
}

@font-face {
  font-family: XMasFont;
  src: url(font/ChristmasLightsIndoor.ttf);
}

@font-face {
  font-family: MerryChristmasFlake;
  src: url(font/MerryChristmasFlake.ttf);
}

header {
  position: relative;
  width: 100vw;
  height: var(--heading-height);
  z-index: 4;
  cursor: default;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  margin-top: 20px;
}

.special-header {
  display: none;
  width: 170px;
  height: 200px;
  position: absolute;
  left: 20px;
  z-index: inherit;
  cursor: pointer;
}

.special-header-end {
  display: none;
  width: 170px;
  height: 140px;
  position: absolute;
  left: 560px;
  top: 20px;
  z-index: inherit;
  cursor: pointer;
}

/* Create angled background with 'before' pseudo-element */
header::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 6em;
  width: 100%;
  height: calc(var(--heading-height) + 10em);
  z-index: -1;
  transform: skewY(-3.5deg);
  background-size: cover;
  border-bottom: .2em solid #fff;
}

body .pre-nav {
  background-color: rgba(220, 120, 0, 0.6);
  border: 0;
}

 header:before {
  background: none;
  background-size: cover;
  background-position-y: top;
  background-position-x: left;
  border: none;
}

@media only screen and (max-width: 1000px) {
  body {
/*    background-image: linear-gradient(rgb(0, 0, 0, .3), rgba(0, 0, 0, 0)), url(images/happy_b_s.jpg); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #040c19;
    background-position-y: calc(var(--heading-height) - 13em);
    background-size: cover;
  }

  body.xmas{
    background-image: unset;
    background-color: #101010;
  }

  body.xmas::before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url(images/xmas_sfondo_cell.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 0;
  }

  body .pre-nav {
    position: relative !important;
  }

  body .links>a {
    font-size: 1.7em;
    color: #fff !important;
    opacity: 0.8;
    text-align: left;
    padding: 15px 0 0 15px;
  }

   header:before {
    background: none !important;
    background-size: cover;
    background-position-y: top;
    background-position-x: left;
    border: none;
  }
}

.cooking{
  background: url(images/image.png) no-repeat left;
  background-position-x: left;
  top: 0px;
}
@media only screen and (max-width: 1000px) {
  .cooking {
    margin: auto;
    padding: 0;
    background: none;
  }
  .cooking h1{
    margin: auto;
  }
}
/*
  LIGHT STYLE
*/
.light header:before {
  background: none;
  background-size: cover;
  background-position-y: top;
  background-position-x: left;
}

body.light {
/*  background:
    linear-gradient(rgb(0, 0, 0, .3), rgba(0, 0, 0, 0)),
    url(images/sfondo_light1.jpg) no-repeat right; */
  background-attachment: fixed;
  background-color: #f5fafb;
  background-position-y: bottom;
  background-size: cover;
  color: dimgray;
}

body.light .pre-nav {
  background: rgba(0, 105, 137, 0.6);
  background-size: 150px;
  background-position-x: right;
  background-position-y: bottom;
}

body.light header h1 {
  color: #006889;
}

body.light header h4 {
  color: #006889;
}
@media only screen and (min-width: 1001px) {
  body {
/*    background:
      linear-gradient(rgb(0, 0, 0, .5), rgba(0, 0, 0, .2)),
      url(images/500_b.jpg) no-repeat,
      linear-gradient(#4e4376, #2b5876); */
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 0;
  }

  body.xmas{
    background-image: unset;
    background-color: #101010;
  }

  body.xmas::before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url(images/xmas_sfondo.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 0;
  }
}
@media only screen and (max-width: 1000px) {

  body.light .links>a {
    font-size: 1.7em;
    color: #fffbe5 !important;
  }

  .light header:before {
    background: none !important;
    background-size: cover;
    background-position-y: top;
    background-position-x: left;
    border: none;
  }

  body.light header h1,
  body.light header h4 {
    color: #006889 !important;
  }
}

header h1 span {
  font-size: calc(calc(132px * var(--expansion-factor)) + 2.6vw);
  top: 43px;
  position: relative;
  left: 58px;
}

header h1,
header h4 {
  font-size: calc(calc(28px * var(--expansion-factor)) + 2.6vw);
  font-weight: calc(700 * var(--expansion-factor));
  letter-spacing: calc(.01em * var(--expansion-factor));
  padding: 0 0 0 12.5rem;
  text-shadow: calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) #111;
  color: #fff;
  margin-top: -49px;
  position: absolute;
  opacity: 0.8;
}

header h4 {
  font-size: calc(calc(8px * var(--expansion-factor)) + 1vw);
  margin-top: 134px;
  padding-left: 29rem;
}

@media only screen and (max-width: 1000px) {
  header h1 span {
    font-size: calc(calc(87px * var(--expansion-factor)) + 2.6vw);
    top: 49px;
    position: relative;
    left: 68px;
  }

  header h1,
  header h4 {
    font-size: calc(calc(28px * var(--expansion-factor)) + 2.6vw);
    font-weight: 700;
    letter-spacing: calc(.01em * var(--expansion-factor));
    padding: 0 0 0 1px;
    text-shadow: calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) #111;
    color: #fff;
    margin-top: -30px;
    position: absolute;
    opacity: 0.8;
  }

  header h4 {
    font-size: calc(calc(9px * var(--expansion-factor)) + 1vw);
    margin-top: 182px;
    padding-left: 192px;
  }

  .special-header-end {
    left: 760px;
    top: 70px;
  }
}

@media only screen and (max-width: 1000px) {
  :root {
    --heading-height: 30em;
  }

  body {
    background-position-y: 0px !important;
  }
}