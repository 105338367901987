.tab-list {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}

.tab-list-item {
  cursor: pointer;
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 10px 20px;
  border: 0 solid var(--accent-primary-color);
  border-bottom-width: 1px;
  color: #ccc;
  font-size: 30px;
  width: 100%;
}

.tab-list-active {
  cursor: default;
  background-color: transparent;
  border-width: 10px 1px 0 1px;
  border-radius: 4px 4px 0 0;
  color: var(--text-color);
}
.tab-content {
  padding: 10px 50px;
  border: 1px solid var(--accent-primary-color);
  border-top: 0;
  margin-bottom: 10px;
}