.simple-select__menu, .badge-select__menu {
  background-color: #17181c !important;
  max-height: 50vh !important;
}

.simple-select__menu-list, .badge-select__menu-list {
  max-height: 50vh !important;
}

.light .simple-select__menu, .light .badge-select__menu {
  background-color: rgba(242, 242, 243, 1) !important;
  color: rgba(23, 24, 28, 0.90) !important;
}

.simple-select__option--is-focused, .badge-select__option--is-focused {
  color: #eee !important;
  background-color: #2a8812 !important;
}

.simple-select__option:hover, .badge-select__option:hover {
  color: #eee !important;
  background-color: #538844 !important;
}

html {
  overflow-x: hidden;
}

body, main, header {
  transition: transform 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 390px;
  padding: 5px;
  transform: translateX(-150%);
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: 0.2s;
  font-size: 20px;
  background-color: rgb(30 30 30 / 90%);
  color: #c6c6bc;
}

.menu path {
  fill: #2a8812;
  cursor: pointer;
}

.simple-select__multi-value__label, .badge-select__multi-value__label {
  color: #eee !important;
}
.menu .badge-select__control, .menu .simple-select__control {
  border-color: #2a8812;
}

.simple-select__multi-value {
  background-color: #538844 !important;
  border-radius: 4px !important;
}
.badge-select__multi-value {
  background-color: transparent !important;
}

.simple-select__multi-value__remove path {
  fill: #eee;
}

.simple-select__multi-value__remove, .badge-select__multi-value__remove {
  cursor: pointer;
}

.simple-select__multi-value__remove:hover{
  background-color: #2a8812 !important;
}
.badge-select__multi-value__remove:hover {
  background-color: transparent !important;
}
.menu span.badge-select__indicator-separator, .menu span.simple-select__indicator-separator{
  background-color: #2a8812;
}

.menu .css-t3ipsp-control {
  box-shadow: 0 0 0 1px #2a8812;
}

.menu .css-t3ipsp-control:hover {
  box-shadow: 0 0 0 1px #2a8812;
  border-color: #2a8812;
}

.btn-search {
  width: calc(50% - 20px);
  margin-top: 20px;
}

.light .menu {
  background-color: rgba(242, 242, 243, 0.90);
  color: #17181c;
}

.menu .content {
  position: absolute;
  margin: 0 auto;
  padding: 30px 20%;
  transform: translateX(100%);
  transition: color 1s ease 1s, background 0.5s ease 1s, transform 1000ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
}

.menu h1 {
  font-size: 35px;
}

.menu label {
  font-size: 20px;
  padding:10px;
  line-height: 2;
}

.menu p {
  margin-bottom: 100px;
  line-height: 2.4;
}

.menu ul {
  margin: 0;
  padding: 20px 0;
  list-style: none;
}

.menu li {
  margin: 0;
  padding: 0;
}

.menu a {
  text-decoration: none;
  display: block;
  padding: 10px 20px;
  padding-left: 120px;
}

.menu a:hover {
  background: slategrey;
}

.menu .close-menu {
  display: block;
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 40px;
  background: none;
  border: none;
  color: #538844;
  cursor: pointer;
  width: unset;
  padding: 0px;
}
.menu .close-menu:hover {
  color: #2a8812;
}

@media only screen and (max-width: 1000px) {
  .nav_is_visible {
    transform: none !important;
  }
  
  .nav_is_visible .menu {
    transform: none !important;
    z-index: 10;
    padding-top: 37px;
    position: fixed;
    width: 95%;
  }
  .menu .close-menu {
    top: 30px;
    font-size: 50px;
  }
  .menu label {
    font-size: 40px;
  }
  .menu h1 {
    font-size: 50px;
  }
  .simple-select, .badge-select {
    font-size: 40px;
  }
  .menu {
    z-index: 1000;
    width: 90%;
  }  
}

.nav_is_visible {
  transform: translateX(400px);
}

.nav_is_visible .menu {
  transform: translateX(-100%);
}

span.tag-cloud-tag:hover {
  text-decoration: underline;
}

span.tag-cloud-tag {
  border-radius: 4px;
  padding: 0px 2px;
  border: 1px solid transparent;
}

.tag-cloud {
  text-align: center;
  overflow: auto;
  max-height: calc(100% - 470px * var(--expansion-factor));
}

.tag-cloud-advanced {
  vertical-align: middle;
  float: right;
  font-size: 20px;
  border-color: #2a8812;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline-color: #2a8812;
}

.badge-selector {
  display: flex;
}
.badge-selector input{
    margin:0;
    padding:0;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}

.badge-selector input:active +label{opacity: .9;}
.badge-selector input:checked +label{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
}

.badge-selector label{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:block;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
            filter: brightness(1.8) grayscale(1) opacity(.7);
}
.badge-selector label:hover{
    -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
       -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
            filter: brightness(1.2) grayscale(.5) opacity(.9);
}


.badge-selector img {
  height: calc(40px * var(--expansion-factor));
  margin: 0 15px;
}

.badge-selector .badge-label {
  margin: auto 0;
}
.badge-selector .badge-img-select {
  min-width: 90px;
}

.badge-select__multi-value .badge-selector .badge-label {
  display: none;
}

.badge-select__multi-value .badge-selector img {
  height: calc(37px * var(--expansion-factor));
  margin: 0;
}

.badge-select__multi-value .badge-selector .badge-img-select {
  min-width: unset;
}
