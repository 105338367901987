:root {
  --items: 1;
  --expansion-factor:1;
}
.transform{
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.on-top{
  opacity: .5;
}

#notification {
  background-color: aquamarine;
  position: fixed;
  bottom: 40px;
  right: 0px;
  padding: 10px;
  transition: all 200ms;
  opacity: 0;
  height: 40px;
  align-content: center;
  border-radius: 5px;
  z-index: 100;
  font-size: 14px;
}

#notification.show {
  right: 90px;
  opacity: 1;
}

#notification #check {
  color: #2a8812;
  font-size: 16px;
}

#notification #content {
  color:#538844;
  font-weight: bold;
}

.btn {
  transform: translateY(0px);
  transition: 0.3s linear;
  background: #538844;
  width: 100px;
  line-height: 1;
  font-size: 30px;
  padding: 10px;
  margin: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #eee;
}

.btn span {
  font-family: 'Farsan', cursive;
  transition: transform 0.3s;
  display: inline-block;
  padding: 10px 20px;
  font-size: calc(1.2em * var(--expansion-factor));
  margin:0;
}

.btn:hover{
  transform: translateY(-6px);
  background: #2a8812;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pre-nav {
  width: 100%;
  height: calc(55px * var(--expansion-factor));
  position: absolute;
  background-size: cover;
  border-bottom: .2em solid #fff;
  z-index: 999;
}

.pdf-viewer-container {
  position: fixed;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  margin: auto;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}
.pdf-viewer {
  position: relative;
  top: 5vh;
  height: 90vh;
  width: 90vw;
  margin: auto;
  display: block;
  box-shadow: 0 0px 500px 15px black;
  border: none;
}
.pdf-download {
  margin: auto;
  display: none;
  width: fit-content;
  margin-top: 50vh;
}

@media only screen and (max-width: 1000px) {
  :root{
    --expansion-factor:2;
  }
  header h4 {
    margin-top: 125px;
  }
  html, body, .root {
      width: 100%;
      position: fixed;
      height: 100%;
      overflow-y: auto;
  }
  .btn {
    font-size: 50px;
  }
  .pdf-viewer {
    display: none;
  }
  .pdf-download {
    display: block;
  }
}

