@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap');
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-attachment: fixed; 
  background-size: cover;
  font-family: "Roboto", Arial, sans-serif;
  min-height: 100vh;
  background-color: #101010;
  color: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hidden {
  display: none !important;
}
#addon{
  position: fixed;
  width: 100vw;
  height: calc(100vh - 180px);
  top: 180px;
  left: 0;
  z-index: 0;
  display: none;
}
.unselctable{
  cursor: default;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
