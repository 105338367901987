@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-attachment: fixed; 
  background-size: cover;
  font-family: "Roboto", Arial, sans-serif;
  min-height: 100vh;
  background-color: #101010;
  color: #fff;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hidden {
  display: none !important;
}
#addon{
  position: fixed;
  width: 100vw;
  height: calc(100vh - 180px);
  top: 180px;
  left: 0;
  z-index: 0;
  display: none;
}
.unselctable{
  cursor: default;
  -webkit-user-select: none; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

:root {
  --items: 1;
  --expansion-factor:1;
}
.transform{
  transform: translateX(0);
}
.on-top{
  opacity: .5;
}

#notification {
  background-color: aquamarine;
  position: fixed;
  bottom: 40px;
  right: 0px;
  padding: 10px;
  transition: all 200ms;
  opacity: 0;
  height: 40px;
  align-content: center;
  border-radius: 5px;
  z-index: 100;
  font-size: 14px;
}

#notification.show {
  right: 90px;
  opacity: 1;
}

#notification #check {
  color: #2a8812;
  font-size: 16px;
}

#notification #content {
  color:#538844;
  font-weight: bold;
}

.btn {
  transform: translateY(0px);
  transition: 0.3s linear;
  background: #538844;
  width: 100px;
  line-height: 1;
  font-size: 30px;
  padding: 10px;
  margin: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #eee;
}

.btn span {
  font-family: 'Farsan', cursive;
  transition: transform 0.3s;
  display: inline-block;
  padding: 10px 20px;
  font-size: calc(1.2em * var(--expansion-factor));
  margin:0;
}

.btn:hover{
  transform: translateY(-6px);
  background: #2a8812;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.pre-nav {
  width: 100%;
  height: calc(55px * var(--expansion-factor));
  position: absolute;
  background-size: cover;
  border-bottom: .2em solid #fff;
  z-index: 999;
}

.pdf-viewer-container {
  position: fixed;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  margin: auto;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}
.pdf-viewer {
  position: relative;
  top: 5vh;
  height: 90vh;
  width: 90vw;
  margin: auto;
  display: block;
  box-shadow: 0 0px 500px 15px black;
  border: none;
}
.pdf-download {
  margin: auto;
  display: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 50vh;
}

@media only screen and (max-width: 1000px) {
  :root{
    --expansion-factor:2;
  }
  header h4 {
    margin-top: 125px;
  }
  html, body, .root {
      width: 100%;
      position: fixed;
      height: 100%;
      overflow-y: auto;
  }
  .btn {
    font-size: 50px;
  }
  .pdf-viewer {
    display: none;
  }
  .pdf-download {
    display: block;
  }
}


@media only screen and (max-width: 1000px) {
  nav {
    position: static;
  }
  /* Appearance */
  .links {
    position: static !important;
  }
}
nav {
  position: fixed;
  z-index: 1001;
}
/* Appearance */
.links {
  font-size: calc(2em  * var(--expansion-factor));
  font-weight: calc(300 * var(--expansion-factor));
}
.links > a {
  color: #fffb;
  padding: .75em;
  text-align: center;
  text-decoration: none;
  transition: all .5s;
}
.links > a:hover {
  background: #ffffff06;
  color: #fff;
}
.links > .line {
  background: #fff;
  height: calc(2px * var(--expansion-factor));
  pointer-events: none;
}

/* The Magic */
.links {
  display: grid;
  grid-template-columns: repeat(var(--items), 1fr);
  position: fixed;
  top: 0;
  left: 0;
}
.links > .line {
  opacity: 0;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  left: var(--left, calc(100% / var(--items) * (var(--index) - 1)));
  width: var(--width, calc(100% / var(--items)));
  --index: 0;
}
.links > a:hover ~ .line {
  opacity: 1;
}

.links > a:nth-of-type(1):hover ~ .line { --index: 1; }
.links > a:nth-of-type(2):hover ~ .line { --index: 2; }
.links > a:nth-of-type(3):hover ~ .line { --index: 3; }
.links > a:nth-of-type(4):hover ~ .line { --index: 4; }
.links > a:nth-of-type(5):hover ~ .line { --index: 5; }
.links > a:nth-of-type(6):hover ~ .line { --index: 6; }
.links > a:nth-of-type(7):hover ~ .line { --index: 7; }
.links > a:nth-of-type(8):hover ~ .line { --index: 8; }
.links > a:nth-of-type(9):hover ~ .line { --index: 9; }
.links > a:nth-of-type(10):hover ~ .line { --index: 10; }
.links > a:last-of-type:hover ~ .line { --index: var(--items); }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  /* Base font size */
  font-size: 10px;

  /* Heading height variable*/
  --heading-height: 20em;
}

@font-face {
  font-family: XMasFont;
  src: url(/static/media/ChristmasLightsIndoor.f16c0cc0.ttf);
}

@font-face {
  font-family: MerryChristmasFlake;
  src: url(/static/media/MerryChristmasFlake.8c03b515.ttf);
}

header {
  position: relative;
  width: 100vw;
  height: var(--heading-height);
  z-index: 4;
  cursor: default;
  -webkit-user-select: none;
  /* Safari */
  /* Firefox */
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  margin-top: 20px;
}

.special-header {
  display: none;
  width: 170px;
  height: 200px;
  position: absolute;
  left: 20px;
  z-index: inherit;
  cursor: pointer;
}

.special-header-end {
  display: none;
  width: 170px;
  height: 140px;
  position: absolute;
  left: 560px;
  top: 20px;
  z-index: inherit;
  cursor: pointer;
}

/* Create angled background with 'before' pseudo-element */
header::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 6em;
  width: 100%;
  height: calc(var(--heading-height) + 10em);
  z-index: -1;
  transform: skewY(-3.5deg);
  background-size: cover;
  border-bottom: .2em solid #fff;
}

body .pre-nav {
  background-color: rgba(220, 120, 0, 0.6);
  border: 0;
}

 header:before {
  background: none;
  background-size: cover;
  background-position-y: top;
  background-position-x: left;
  border: none;
}

@media only screen and (max-width: 1000px) {
  body {
/*    background-image: linear-gradient(rgb(0, 0, 0, .3), rgba(0, 0, 0, 0)), url(images/happy_b_s.jpg); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: #040c19;
    background-position-y: calc(var(--heading-height) - 13em);
    background-size: cover;
  }

  body.xmas{
    background-image: unset;
    background-color: #101010;
  }

  body.xmas::before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url(/static/media/xmas_sfondo_cell.98622974.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 0;
  }

  body .pre-nav {
    position: relative !important;
  }

  body .links>a {
    font-size: 1.7em;
    color: #fff !important;
    opacity: 0.8;
    text-align: left;
    padding: 15px 0 0 15px;
  }

   header:before {
    background: none !important;
    background-size: cover;
    background-position-y: top;
    background-position-x: left;
    border: none;
  }
}

.cooking{
  background: url(/static/media/image.a3c7f59d.png) no-repeat left;
  background-position-x: left;
  top: 0px;
}
@media only screen and (max-width: 1000px) {
  .cooking {
    margin: auto;
    padding: 0;
    background: none;
  }
  .cooking h1{
    margin: auto;
  }
}
/*
  LIGHT STYLE
*/
.light header:before {
  background: none;
  background-size: cover;
  background-position-y: top;
  background-position-x: left;
}

body.light {
/*  background:
    linear-gradient(rgb(0, 0, 0, .3), rgba(0, 0, 0, 0)),
    url(images/sfondo_light1.jpg) no-repeat right; */
  background-attachment: fixed;
  background-color: #f5fafb;
  background-position-y: bottom;
  background-size: cover;
  color: dimgray;
}

body.light .pre-nav {
  background: rgba(0, 105, 137, 0.6);
  background-size: 150px;
  background-position-x: right;
  background-position-y: bottom;
}

body.light header h1 {
  color: #006889;
}

body.light header h4 {
  color: #006889;
}
@media only screen and (min-width: 1001px) {
  body {
/*    background:
      linear-gradient(rgb(0, 0, 0, .5), rgba(0, 0, 0, .2)),
      url(images/500_b.jpg) no-repeat,
      linear-gradient(#4e4376, #2b5876); */
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 0;
  }

  body.xmas{
    background-image: unset;
    background-color: #101010;
  }

  body.xmas::before {
    content: ' ';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url(/static/media/xmas_sfondo.408cfc50.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 0;
  }
}
@media only screen and (max-width: 1000px) {

  body.light .links>a {
    font-size: 1.7em;
    color: #fffbe5 !important;
  }

  .light header:before {
    background: none !important;
    background-size: cover;
    background-position-y: top;
    background-position-x: left;
    border: none;
  }

  body.light header h1,
  body.light header h4 {
    color: #006889 !important;
  }
}

header h1 span {
  font-size: calc(calc(132px * var(--expansion-factor)) + 2.6vw);
  top: 43px;
  position: relative;
  left: 58px;
}

header h1,
header h4 {
  font-size: calc(calc(28px * var(--expansion-factor)) + 2.6vw);
  font-weight: calc(700 * var(--expansion-factor));
  letter-spacing: calc(.01em * var(--expansion-factor));
  padding: 0 0 0 12.5rem;
  text-shadow: calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) #111;
  color: #fff;
  margin-top: -49px;
  position: absolute;
  opacity: 0.8;
}

header h4 {
  font-size: calc(calc(8px * var(--expansion-factor)) + 1vw);
  margin-top: 134px;
  padding-left: 29rem;
}

@media only screen and (max-width: 1000px) {
  header h1 span {
    font-size: calc(calc(87px * var(--expansion-factor)) + 2.6vw);
    top: 49px;
    position: relative;
    left: 68px;
  }

  header h1,
  header h4 {
    font-size: calc(calc(28px * var(--expansion-factor)) + 2.6vw);
    font-weight: 700;
    letter-spacing: calc(.01em * var(--expansion-factor));
    padding: 0 0 0 1px;
    text-shadow: calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) calc(.022em * var(--expansion-factor)) #111;
    color: #fff;
    margin-top: -30px;
    position: absolute;
    opacity: 0.8;
  }

  header h4 {
    font-size: calc(calc(9px * var(--expansion-factor)) + 1vw);
    margin-top: 182px;
    padding-left: 192px;
  }

  .special-header-end {
    left: 760px;
    top: 70px;
  }
}

@media only screen and (max-width: 1000px) {
  :root {
    --heading-height: 30em;
  }

  body {
    background-position-y: 0px !important;
  }
}

main {
  padding: 0 40px 0;
}
.explosion {
  position: absolute;
  width: 400px;
  height: 400px;
  pointer-events: none;
}
.explosion .particle {
  position: absolute;
  width: calc(10px * var(--expansion-factor));
  height: calc(10px * var(--expansion-factor));
  border-radius: 50%;
  animation: pop 1s reverse forwards;
}
.egg {
  background-size: cover;
  width: calc(50px * var(--expansion-factor));
  height: calc(50px * var(--expansion-factor));
  position: absolute;
  top: 60px;
  left: 80px;
  z-index: 4;
  cursor: pointer;
}
@keyframes pop {
  from {
    opacity: 0;
  }
  to {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
}

@font-face {
  font-family: Black-Rose;
  src: url(/static/media/BLACKR.5dcbd4b1.ttf);
}
:root {
  --card-height: 400px;
  --card-width: calc(var(--card-height) / 1.5);
}
* {
  box-sizing: border-box;
}
.card-home-container {
/*  width: 100vw;*/
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.card-home {
  width: var(--card-width);
  height: var(--card-height);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 36px;
  perspective: 2500px;
  margin: 10px 50px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  padding-bottom: 60px;
  border-radius: 4px;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.card-home:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 130px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 127%
  );
}

.card-home:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.card-home:hover .wrapper::after {
  height: 120px;
  opacity: 0;
}
.card-home .title {
  width: 100%;
  transition: transform 0.5s;
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
    1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
  font-size: 3em;
  font-family: Black-Rose;
  color: darkred;
  margin: 11px 0;
}
.card-home:hover .title {
  transform: translate3d(0%, -50px, 100px);
  z-index: 0;
}

.character {
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
  cursor: pointer;
}

.card-home:hover .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}
body.light .card-home .title {
  color: #3185a0;
}

#cardCategories {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%
}
@media only screen and (max-width: 1000px) {
  .character {
    opacity: 1;
  }
}
.simple-select__menu, .badge-select__menu {
  background-color: #17181c !important;
  max-height: 50vh !important;
}

.simple-select__menu-list, .badge-select__menu-list {
  max-height: 50vh !important;
}

.light .simple-select__menu, .light .badge-select__menu {
  background-color: rgba(242, 242, 243, 1) !important;
  color: rgba(23, 24, 28, 0.90) !important;
}

.simple-select__option--is-focused, .badge-select__option--is-focused {
  color: #eee !important;
  background-color: #2a8812 !important;
}

.simple-select__option:hover, .badge-select__option:hover {
  color: #eee !important;
  background-color: #538844 !important;
}

html {
  overflow-x: hidden;
}

body, main, header {
  transition: transform 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 390px;
  padding: 5px;
  transform: translateX(-150%);
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: 0.2s;
  font-size: 20px;
  background-color: rgb(30 30 30 / 90%);
  color: #c6c6bc;
}

.menu path {
  fill: #2a8812;
  cursor: pointer;
}

.simple-select__multi-value__label, .badge-select__multi-value__label {
  color: #eee !important;
}
.menu .badge-select__control, .menu .simple-select__control {
  border-color: #2a8812;
}

.simple-select__multi-value {
  background-color: #538844 !important;
  border-radius: 4px !important;
}
.badge-select__multi-value {
  background-color: transparent !important;
}

.simple-select__multi-value__remove path {
  fill: #eee;
}

.simple-select__multi-value__remove, .badge-select__multi-value__remove {
  cursor: pointer;
}

.simple-select__multi-value__remove:hover{
  background-color: #2a8812 !important;
}
.badge-select__multi-value__remove:hover {
  background-color: transparent !important;
}
.menu span.badge-select__indicator-separator, .menu span.simple-select__indicator-separator{
  background-color: #2a8812;
}

.menu .css-t3ipsp-control {
  box-shadow: 0 0 0 1px #2a8812;
}

.menu .css-t3ipsp-control:hover {
  box-shadow: 0 0 0 1px #2a8812;
  border-color: #2a8812;
}

.btn-search {
  width: calc(50% - 20px);
  margin-top: 20px;
}

.light .menu {
  background-color: rgba(242, 242, 243, 0.90);
  color: #17181c;
}

.menu .content {
  position: absolute;
  margin: 0 auto;
  padding: 30px 20%;
  transform: translateX(100%);
  transition: color 1s ease 1s, background 0.5s ease 1s, transform 1000ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
}

.menu h1 {
  font-size: 35px;
}

.menu label {
  font-size: 20px;
  padding:10px;
  line-height: 2;
}

.menu p {
  margin-bottom: 100px;
  line-height: 2.4;
}

.menu ul {
  margin: 0;
  padding: 20px 0;
  list-style: none;
}

.menu li {
  margin: 0;
  padding: 0;
}

.menu a {
  text-decoration: none;
  display: block;
  padding: 10px 20px;
  padding-left: 120px;
}

.menu a:hover {
  background: slategrey;
}

.menu .close-menu {
  display: block;
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 40px;
  background: none;
  border: none;
  color: #538844;
  cursor: pointer;
  width: unset;
  padding: 0px;
}
.menu .close-menu:hover {
  color: #2a8812;
}

@media only screen and (max-width: 1000px) {
  .nav_is_visible {
    transform: none !important;
  }
  
  .nav_is_visible .menu {
    transform: none !important;
    z-index: 10;
    padding-top: 37px;
    position: fixed;
    width: 95%;
  }
  .menu .close-menu {
    top: 30px;
    font-size: 50px;
  }
  .menu label {
    font-size: 40px;
  }
  .menu h1 {
    font-size: 50px;
  }
  .simple-select, .badge-select {
    font-size: 40px;
  }
  .menu {
    z-index: 1000;
    width: 90%;
  }  
}

.nav_is_visible {
  transform: translateX(400px);
}

.nav_is_visible .menu {
  transform: translateX(-100%);
}

span.tag-cloud-tag:hover {
  text-decoration: underline;
}

span.tag-cloud-tag {
  border-radius: 4px;
  padding: 0px 2px;
  border: 1px solid transparent;
}

.tag-cloud {
  text-align: center;
  overflow: auto;
  max-height: calc(100% - 470px * var(--expansion-factor));
}

.tag-cloud-advanced {
  vertical-align: middle;
  float: right;
  font-size: 20px;
  border-color: #2a8812;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline-color: #2a8812;
}

.badge-selector {
  display: flex;
}
.badge-selector input{
    margin:0;
    padding:0;
    -webkit-appearance:none;
            appearance:none;
}

.badge-selector input:active +label{opacity: .9;}
.badge-selector input:checked +label{
    -moz-filter: none;
         filter: none;
}

.badge-selector label{
    cursor:pointer;
    background-size:contain;
    background-repeat:no-repeat;
    display:block;
    transition: all 100ms ease-in;
    -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
         filter: brightness(1.8) grayscale(1) opacity(.7);
}
.badge-selector label:hover{
    -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
         filter: brightness(1.2) grayscale(.5) opacity(.9);
}


.badge-selector img {
  height: calc(40px * var(--expansion-factor));
  margin: 0 15px;
}

.badge-selector .badge-label {
  margin: auto 0;
}
.badge-selector .badge-img-select {
  min-width: 90px;
}

.badge-select__multi-value .badge-selector .badge-label {
  display: none;
}

.badge-select__multi-value .badge-selector img {
  height: calc(37px * var(--expansion-factor));
  margin: 0;
}

.badge-select__multi-value .badge-selector .badge-img-select {
  min-width: unset;
}

.light div .summary{
  color: dimgray;
}
div .summary{
  color: #fff;
}
.search-results {
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 5;
  transition: margin-left .5s; /* If you want a transition effect */
  top: 40px;
  position: relative;
  width: 1000px;
  left: 50px;
  padding-bottom: 20px;
}

.search-results .search-result:first-child{
  border-radius: 5px 5px 0 0;
}

.search-results .search-result:last-child{
  border-radius: 0 0 5px 5px;
}

.search-result {
  margin-bottom: 1px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background: hsla(0,0%,100%,.9);
  color: #17181C;
}

.search-result > div{
  margin: 0;
  padding: 0;
}

.search-result > div.search-result-link {
  display: flex;
}

.search-result > div.search-result-content {
  font-family: "Bree Serif",serif;
  padding-left: 5px;
  padding-top: 10px;
}

.search-result img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 25px;
}

.search-result a {
  text-decoration: none;
}

.search-result a:hover {
  color: #006621;
}

.search-result h1,.search-result  h2, .search-result h3 {
  display: block;
  position: relative;
  font-size: calc(13px*var(--expansion-factor));
  margin: 0;
  color: #344055;
  font-family: "Bebas Neue",cursive;
  line-height: 1.1;
}

.search-result h1 {
  vertical-align: top;
  font-size: 30px;
}

.search-result h2 {
  font-size: 17px;
}

.search-result ul.search-result-ingredients {
  display: flex;
  list-style-position: inside;
  margin: 0; 
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-result ul.search-result-ingredients li {
  padding: 2px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}
.search-result ul.search-result-ingredients li.highlight-ing {
  color: red;
  font-weight: 800;
}

.search-result ul.search-result-ingredients li::marker {
  content: "- ";
}

.search-result ul.search-result-ingredients li:nth-child(3n)::marker {
  content: "- ";
}

.search-result ul.search-result-ingredients li:nth-child(3n - 1)::marker {
  content:"- ";
}
.search-result ul.search-result-ingredients li.highlight-ing::marker {
  color: red;
  font-weight: 800;
}
.search-result-a {
  vertical-align: top;
  display: block;
}

@media only screen and (max-width: 1000px) {
  .search-results {
    top: 40px;
    left: -1px;
    width: 900px;
    font-size: 40px;
  }
  .search-result-a {
    font-size: 15px;
  }
  .search-result img {
    width: 90px;
    height: 90px;
    margin-right: 10px;
    border-radius: 45px;
  }
  .search-result h2 {
    font-size: 34px;
  }
  .search-result h1 {
    font-size: 60px;
  }

}
/* Product Card! */
.container{
/* Shadow the Card */
/*  box-shadow: 0 15px 30px 1px grey; */
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.90);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 50px;
  height: calc(250px * var(--expansion-factor));
  width: calc(450px * var(--expansion-factor));
  /* remove to display center */
  display: inline-block;  
  position: relative;
  z-index: 2;
}
.product-details {
  position: relative;
  text-align: left;
  overflow: hidden;
  height: 100%;
  width: 50%;
  display: inline-block;
}
.product-details-date {
  font-size: calc(12px * var(--expansion-factor));
  color: #374254;
  margin: 5px 10px;
  opacity: 0.8;
  text-align: right;
}
.container .product-details h1{
  font-family: 'Bebas Neue', cursive;
  display: block;
  position: relative;
  font-size: calc(30px * var(--expansion-factor));
  color: #344055;
  margin: 0;
}
.container .product-details h1:before{
  position: absolute;
  content: '';
  right: 0%; 
  top: 0%;
  transform: translate(25px, -15px);
  font-family: 'Bree Serif', serif;
  display: inline-block;
  background: #ffe6e6;
  border-radius: 5px;
  font-size: calc(14px * var(--expansion-factor));
  padding: 5px;
  color: white;
  margin: 0;
  animation: chan-sh 6s ease infinite;
}
div.product-details a {
    position: relative;
    display: block;
    padding-left: 20px;
}
.hint-star {
  display: inline-block;
  margin-left: 0.5em;
  color: gold;
  width: 50%;
  font-size: calc(15px * var(--expansion-factor));
}
.hint-star i {
  text-shadow: 2px 0 0 #0002, -2px 0 0 #0002, 0 2px 0 #0002, 0 -2px 0 #0002, 1px 1px #0002, -1px -1px 0 #0002, 1px -1px 0 #0002, -1px 1px 0 #0002;
}
.badge-container img {
  width: auto;
  height: auto !important;
}
.badge-img {
  display: inline-flex;
  height: calc(35px * var(--expansion-factor));
  margin-right: 10px;
  width: calc(35px * var(--expansion-factor));
  cursor: pointer;
}
.badge-container {
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 1px;
}
#container .product-details > p {
  font-family: 'EB Garamond', serif;
  text-align: center;
  font-size: calc(18px * var(--expansion-factor));
  color: #7d7d7d;
  padding-left: 30px;  
}
.control{
  display: none;
  position: absolute;
  bottom: 20%;
  left: 22.8%;
  
}
.btn .price, .shopping-cart{
  background: #333;
  border: 0;
  margin: 0;
}
.btn .price {
  transform: translateX(-10%);
  padding-right: 15px;
}
.btn .shopping-cart {
  transform: translateX(-100%);
  position: absolute;
  background: #333;
  z-index: 1;
  left: 0;
  top: 0;
}
.btn .buy {z-index: 3; font-weight: bolder;}
.btn:hover .price {transform: translateX(-110%);}
.btn:hover .shopping-cart {transform: translateX(0%);}
.product-image {
  transition: all 0.3s ease-out;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 50%;
  display: inline-block;
}
.container img {
  height: 100%;
}
.info {
    background: rgba(27, 26, 26, 0.9);
    font-family: 'Bree Serif', serif;
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    position: absolute;
    line-height: 1.8;
    text-align: left;
    font-size: calc(105% * var(--expansion-factor));
    cursor: no-drop;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: 0%;
    top: 0;
}
.info-commit {
    background: rgba(27, 26, 26, 0.9);
    font-family: 'Bree Serif', serif;
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    position: absolute;
    line-height: 1.8;
    text-align: left;
    font-size: calc(105% * var(--expansion-factor));
    cursor: no-drop;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: -100%;
    top: 0;
}
.info h2 {text-align: center}
.product-image:hover .info{transform: translateX(0);}
.info ul li{transition: 0.3s ease;}
.info ul li:hover{transform: translateX(calc(50px * var(--expansion-factor))) scale(1.3);}
.product-image:hover img {transition: all 0.3s ease-out;}
.product-image:hover img {transform: scale(1.2, 1.2);}
.container #tagCloud {
  position: relative;
  height: 60px;
  display: inline-block;
  overflow-y: hidden;
  padding-left: 30px;
  width: calc(100% - 40px);
  cursor: default;
}
.container #tagCloud:hover {
  height: 250px;
  transition: all 1s ease-out;
}
.container #tagCloud label {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}
.card-cloud {
  text-align: center;
}

.card-cloud span.tag-cloud-tag:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 1000px) {
  .container {
    display: block;
    margin: 5em auto;
  }
}

.btn-share i {
  opacity: 0;
  font-size: calc(22px * var(--expansion-factor));
  color: #EEEEED;
  will-change: transform;
  transform: scale(.1);
  transition: all .3s ease;
  cursor: pointer;
}

.btn-share {
  position: absolute;
  bottom: 10px;
  left: 12.8%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: default;
  width: calc(150px * var(--expansion-factor));
  height: calc(42px * var(--expansion-factor));
  background: #538844;
  border-radius: calc(5px * var(--expansion-factor));
  padding: 0 18px;
  will-change: transform;
  transition: all .2s ease-in-out;
}

.btn-share:hover {
  /* transition-delay: .4s; */
  transform: scale(calc(1.1 * var(--expansion-factor)));
  transform: translateY(-6px);
  background: #2a8812;
}

.btn-share span {
  position: absolute;
  z-index: 99;
  width: calc(240px * var(--expansion-factor));
  height: calc(72px * var(--expansion-factor));
  border-radius: calc(5px * var(--expansion-factor));
  font-family: "Open Sans", Avenir, "Segoe UI", Arial, sans-serif;
  font-size: calc(20px * var(--expansion-factor));
  text-align: center;
  line-height: calc(70px * var(--expansion-factor));
  letter-spacing: calc(2px * var(--expansion-factor));
  color: #EEEEED;
  background-color: #333;
  padding: 0 18px;
  transition: all 1.2s ease;
}

.btn-share .share-ico {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(240px * var(--expansion-factor));
  height: calc(64px * var(--expansion-factor));
  border-radius: calc(80px * var(--expansion-factor));
}

.btn-share .share-ico i:nth-of-type(1) {
  transition-delay: 1.1s;
}

.btn-share .share-ico i:nth-of-type(2) {
  transition-delay: .9s;
}

.btn-share .share-ico i:nth-of-type(3) {
  transition-delay: .7s;
}

.btn-share .share-ico i:nth-of-type(4) {
  transition-delay: .4s;
}

.btn-share:hover span {
  transition-delay: .25s;
  transform: translateX(calc(-280px * var(--expansion-factor)))
}

.btn-share:hover i {
  opacity: 1;
  transform: scale(1);
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#sidebarContent .button {
  position: relative;
  top: 12px;
  z-index: 5;
  color: white;
  padding: 2px;
  font-size: 30px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.90);
  border-radius: 0.25em 0 0 0.25em;
  display: inline-block;
  width: 42px;
}
#sidebarContent .button svg {
  margin: auto;
  display: block;
  width: 90%;
  height: 90%;
  margin-top: 2px;
}

#sidebarContent {
  font-size: 19px;
  z-index: 5;
  transition: margin-left .5s; /* If you want a transition effect */
  position: relative;
  width: 100%;
  padding: 0 10% 0 10%;
}
.sticky {
  display: unset;
  position: fixed !important;
  top: 0;
  left: 0;
  padding-bottom: 20px !important;
  padding-left: calc(10% + 10px) !important;
  padding-right: calc(10% + 50px) !important;
  box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.75);
  background: url(/static/media/image.a3c7f59d.png) no-repeat left;
  background-position-x: 3%;
  background-position-y: 0;
  background-color: rgb(0, 0, 0, 0.4);
  background-size: contain;
  animation: showBG 1s none;
}
/* Style the navigation menu */
#sidebarContent .topnav {
  cursor: text;
  color: #17181c;
  background-color: rgba(255, 255, 255, 0.90);
	border-radius: 0 .25em .25em 0;
	box-shadow: none;
  display: inline-block;
	padding: 9.5px;
	transition-delay: calc(var(--dur) * 0.25);
	width: calc(100% - 55px);
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  position: relative;
  height: 40px;
  padding-left: 0;
}
.search-bar {
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  width: calc(100% - 90px);
}
.search-bar:hover{
  border: none;
}
.search-bar:visited{
  border: none;
}
.search-bar:focus { 
  border:none; 
  font-size: 20px;
  transition: 1s;
}
  /* Position and style the close button (top right corner) */
  .closebtn {
    display: block;
    float: left;
    cursor: pointer;
    padding: 0 3px 3px 3px;
    font-size: 20px;
    border-radius: 20%;
    caret-color: var(--primary);
    width: 30px;
    height: 30px;
    -webkit-appearance: none;
            appearance: none;
    color: #17181c;
    text-align: center;
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  }
  raga-switch-theme {
    position: relative;
    top: -7px;
    float: right;
  }

  @media only screen and (max-width: 1000px) {
    div .sidebar {padding-top: 15px;}
    div .sidebar a {font-size: 18px;}
    #sidebarContent {
      padding: 0 7% 0 19%;
      font-size: 40px;
    }
    .sticky {
      padding-left: calc(17% + 10px) !important;
      padding-right: calc(9% + 50px) !important;
    }
    #sidebarContent .button {
      width: 59px;
      top: 7px;
    }
    #sidebarContent .topnav {
      left: 0;
      width: calc(100% - 89px);
      height: 55px;
    }
    #sidebarContent .fa.fa-search {
      font-size: 33px;
    }
    .search-bar {
      font-size: 33px;
      margin: auto;
      position: relative;
      top: -10px;
    }
    .search-bar:focus { 
      font-size: 35px;
    }
    raga-switch-theme {
      top: -25px;
    }
  }
  
  @keyframes showBG {
    from {
      background-color: transparent;
    }
    to {
      background-color: rgb(0, 0, 0, 0.4);
    }
    from {
      background-position-y: -100px;
    }
    to {
      background-position-y: 0;
    }
  }

.root-normalize-container {
  font-size: 20px;
  background-color: var(--primary-color);
  margin: 0px 10px 20px 10px;
  padding: 0 10px;
  border-radius: 14px;
  left: -110vw;
  animation-duration: 1s;
  position: absolute;
  width: calc(100vw - 60px);
  color: var(--text-color);
}
.root-normalize-container.swype-right {
  left: 0vw;
  animation-name: swypeRight;
}
.root-normalize-container.swype-left {
  animation-name: swypeLeft;
}
.root-normalize-container label{
  margin: 10px 20px;
  display: inline-block;
}
.root-normalize-container .input-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 5px;
  border-color: transparent;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  padding-left: 20px;
}
.root-normalize-container .input-container:has(input:focused) {
  border-color: var(--accent-primary-color);
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-left-width: 10px;
}
.root-normalize-container .input-container:has(input:focus) {
  border-color: var(--accent-primary-color);
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-left-width: 10px;
}
.root-normalize-container .input-container:has(input:focus-visible) {
  border-color: var(--accent-primary-color);
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-left-width: 10px;
}
.root-normalize-container .input-container.input-error {
  border-color: var(--error-color) !important;
  box-shadow: 0 0 0 1px var(--error-color) !important;
}
.root-normalize-container input[type="text"]:focus, .root-normalize-container input[type="text"]:focus-visible {
  border: none;
  outline: none;
}
.root-normalize-container input[type="text"] {
	border-radius: 4px;
	display: inline-block;
	position: relative;
	width: 100%;
  line-height: 1;
	border: none;
  font-size: 20px;
  margin: auto;
  padding: 6px 0px;
}

.root-normalize-container .single-select {
	color: black;
}
.btn-normalize {
  width:100%;
  margin: auto;
  border-color: var(--accent-text-color);
  border-style: solid;
  border-width: 2px;
  color: var(--accent-text-color);
  background-color: var(--accent-primary-color);
  text-align: center;
}
.btn-normalize:hover{
  background-color: var(--accent-text-color);
  border-color: var(--accent-primary-color);
  color: var(--accent-primary-color);
}
.btn-action {
  width: unset;
  float: right;
}
.ingr-list-action {
  font-size: 20px;
}

.root-normalize-container .tag-cloud {
  max-height: calc(100vh - 550px * var(--expansion-factor));
}

.tag-cloud-filter {
  padding: 6px !important;
  width: unset !important;
}
div.tag-cloud-filter {
  width: calc(100% - 188px) !important;
  display: inline-block;
}
.new-tag {
  border-radius: 4px 0 0 4px !important;
}
.btn-new-tag {
  width: 50px !important;
  height: 35.5px;
  float: none;
  font-size: 11px;
  border-radius: 0px 4px 4px 0px;
  top: -3px !important;
  border-left-color: #FFF;
  height: unset !important;
}
.btn-new-tag:hover {
  transform: unset;
}

.root-normalize-container .css-t3ipsp-control {
  box-shadow: 0 0 0 1px var(--accent-primary-color);
}

.root-normalize-container .css-t3ipsp-control:hover {
  box-shadow: 0 0 0 1px var(--accent-primary-color);
  border-color: var(--accent-primary-color);
}
.root-normalize-container .css-d7l1ni-option {
  background-color: var(--accent-secondary-color);
}

.root-normalize-container .css-tr4s17-option, .root-normalize-container .css-d7l1ni-option:active {
  background-color: var(--accent-primary-color);
  color: var(--accent-text-color);
}
@keyframes swypeRight {
  0% {
    left: 110vw;
  }
  100% {
    left: 0vw;
  }
}

@keyframes swypeLeft {
  0% {
    left: 0vw;
  }
  100% {
    left: -110vw;
  }
}

@media only screen and (max-width: 1000px) {
  .root-normalize-container {
    touch-action: pan-x pan-y;
    font-size: 30px;
  }
  .root-normalize-container input{
    font-size: 20px;
  }
  .btn-normalize{
    height: 150px;
  }
  a.btn-normalize{
    box-sizing: border-box;
    padding: 45px 0;
  }
  .btn-action {
    width: unset;
    float: right;
    top:-5px;
    height: unset;
  }
  .ingr-list-action {
    top: 10px;
  }
}
.card {
  --background-checkbox: var(--accent-primary-color);
  --card-height: 140px;
  --card-width: 100px;
  --blend-mode: overlay;
  --transition: 0.15s;
  -webkit-user-select: none;
          user-select: none;
  display: inline-block;
}
.card__input {
  position: absolute;
  display: block;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}
.card__input:checked ~ .card__body .card__body-cover-checkbox {
  --check-bg: var(--background-checkbox);
  --check-border: var(--accent-text-color);
  --check-scale: 1;
  --check-opacity: 1;
}
.card__input:checked ~ .card__body .card__body-cover-checkbox--svg {
  --stroke-color: var(--accent-text-color);
  --stroke-dashoffset: 0;
}
.card__input:checked ~ .card__body .card__body-cover-image {
  --filter-bg: grayscale(0);
}
.card__input:disabled ~ .card__body {
  cursor: not-allowed;
  opacity: 0.5;
}
.card__input:disabled ~ .card__body:active {
  --scale: 1;
}
.card__body {
  display: grid;
  grid-auto-rows: calc(var(--card-height)) auto;
  height: var(--card-height);
  width: var(--card-width);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform var(--transition), box-shadow var(--transition);
  transform: scale(var(--scale, 1)) translateZ(0);
}
.card__body:active {
  --scale: 0.96;
}
.card__body-cover {
  --c-width: 100%;
  --c-height: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}
.card__body-cover-image {
  width: var(--c-width);
  height: var(--c-height);
  object-fit: cover;
  filter: var(--filter-bg, grayscale(1));
}
.card__body-cover-checkbox {
  background: var(--check-bg, var(--background-checkbox));
  border: 2px solid var(--check-border, #fff);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  opacity: var(--check-opacity, 0);
  transition: transform var(--transition), opacity calc(var(--transition) * 1.2) linear;
  transform: scale(var(--check-scale, 0));
}
.card__body-cover-checkbox--svg {
  width: 13px;
  height: 11px;
  display: inline-block;
  vertical-align: top;
  fill: none;
  margin: 7px 0 0 5px;
  stroke: var(--stroke-color, #fff);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: var(--stroke-dashoffset, 16px);
  transition: stroke-dashoffset 0.4s ease var(--transition);
}
.radio-btn-container {
  position: relative;
  top: -70px;
  margin-bottom: -70px;
}
.dropdown-item {
  height: 100%;
  animation-name: hideDropdown;
  animation-duration: 1s;
  overflow: hidden;
  margin: 10px 20px;
  border: 1px solid var(--accent-primary-color);
  border-radius: 4px 4px 0 0;
  max-height: 0px;
  display:block;
  padding:0;
  width: calc(100% - 40px);
}
.dropdown-active {
  animation-name: showDropdown;
  border-top-width: 10px;
  max-height: 3500px;
  display:table;
}
.dropdown-item-content{
  padding: 10px 20px;
}
.dropdown-item-title {
  font-size: 35px;
  margin: 10px 20px;
  min-height: 55px;
}
.dropdown-item-title i{
  padding-right: 15px;
}
.dropdown-item-header {
    background-color: var(--accent-primary-color);
    font-size: 25px;
    font-weight: bold;
    padding: 0 10px 10px 10px;
    color: var(--accent-text-color);
}
.dropdown-item-description {
  width: 100%;
  min-height: 200px;
  border: 1px solid var(--accent-primary-color);
  border-top-width: 10px;
  background-color: #fffc;
  border-radius: 4px 4px 0 0;
  animation-duration: 2s;
  font-size: 20px;
}

.dropdown-item p{
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0.8em;
          margin-block-end: 0.8em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  text-indent: 0.5em;
}

.dropdown-item-description:focus, .dropdown-item-description:focus-visible {
  border: 1px solid var(--accent-primary-color);
  border-top-width: 10px;
  background-color: #ffff;
  outline: none;
  animation-name: focusTextarea;
}
.dropdown-item-ingredient {
  -webkit-margin-before: 20px;
          margin-block-start: 20px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

.dropdown-item-cell {
  display:table-cell;
  border-right: 1px solid var(--accent-primary-color)
}
.dropdown-item-cell:last-of-type {
  border-right: none;
}

@keyframes focusTextarea {
  from {
    background-color: #fffc;
  }
  to {
    background-color: #ffff;
  }
}
@keyframes showDropdown {
  from {
    max-height: 0px;
    border-top-width: 1px;
    display: block;
  }
  to {
    max-height: 3500px;
    border-top-width: 10px;
    display: table;
  }
}
@keyframes hideDropdown {
  from {
    max-height: 3500px;
    border-top-width: 10px;
  }
  to {
    max-height: 0px;
    border-top-width: 1px;
  }
}
.root-normalize-container span.tag-cloud-tag {
  background-color: var(--secondary-color);
  border-color: #fffa;
  margin-top: 5px !important;
}
#tagsContainer span.tag-cloud-tag {
  cursor: pointer;
}
.root-normalize-container span.tag-cloud-tag:hover {
  text-decoration: none !important;
}
span.tag-cloud-tag:before {
  content: "#";
}
@media only screen and (max-width: 1000px) {
  .dropdown-item-cell {
    display: block;
    width: 100% !important;
  }
  .dropdown-item {
    display: block;
  }
}
.ingredient-list {
  border-bottom: 1px solid var(--accent-primary-color);
  width: calc(100% - 40px);
}
.tab-list {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}

.tab-list-item {
  cursor: pointer;
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 10px 20px;
  border: 0 solid var(--accent-primary-color);
  border-bottom-width: 1px;
  color: #ccc;
  font-size: 30px;
  width: 100%;
}

.tab-list-active {
  cursor: default;
  background-color: transparent;
  border-width: 10px 1px 0 1px;
  border-radius: 4px 4px 0 0;
  color: var(--text-color);
}
.tab-content {
  padding: 10px 50px;
  border: 1px solid var(--accent-primary-color);
  border-top: 0;
  margin-bottom: 10px;
}
.root-normalize-container, #themeSelector {
  --primary-color: #219EBCDE;
  --secondary-color: #023047;
  --text-color: #FFFFFF;
  --accent-primary-color: #FFB703;
  --accent-secondary-color: #FFDABC;
  --accent-text-color: #000000;
  --error-color: #FB8500;
}
.root-normalize-container.cz, #themeSelector.cz {
  --primary-color: #F5DC40DE;
  --secondary-color: #F5DC40;
  --text-color: #C30613;
  --accent-primary-color: #C30613;
  --accent-secondary-color: #C30613AA;
  --accent-text-color: #F5DC40;
  --error-color: #C30613;
}
.root-normalize-container.first, #themeSelector.first {
  --primary-color: #264653DE;
  --secondary-color: #2A9D8F;
  --text-color: #FFFFFF;
  --accent-primary-color: #F4A261;
  --accent-secondary-color: #FFDABC;
  --accent-text-color: #000000;
  --error-color: #FF0000;
}
.root-normalize-container.second, #themeSelector.second {
  --primary-color: #780000DE;
  --secondary-color: #C1121F;
  --text-color: #FDF0D5;
  --accent-primary-color: #003049;
  --accent-secondary-color: #669BBC;
  --accent-text-color: #FFFFFF;
  --error-color: #C1121F;
}
.root-normalize-container.xmas, #themeSelector.xmas {
  --primary-color: #2A7539DE;
  --secondary-color: #64AC3A;
  --text-color: #F5E9CC;
  --accent-primary-color: #CE3639;
  --accent-secondary-color: #ED9D82;
  --accent-text-color: #F5E9CC;
  --error-color: #CE3639;
}
#themeSelector {
  width: 170px;
  position: absolute;
  top: 50px;
  right: 100px;
  z-index: 1000;
}
#themeSelector label {
  font-size: 20px;
}

raga-photo-gallery {
  display: inline-block;
  width: 100vw;
}
.bg-hidden {
  top: -100%;
  opacity: 0;
}

.bg-visible {
  top: 0;
  opacity: 1;
}
.content-wrapper {
  width: 100%;
  padding: 1em 1%;
  padding-top: 35px;
  font-size: calc(2em * var(--expansion-factor));
  display: table;
}

.content-wrapper > * {
  position: relative;
  display: table-cell !important;
}

.content-wrapper p {
  margin: 12px 0;
  padding-right: 5%;
  text-align: justify;
}

.content-wrapper h1, .content-wrapper h2 {
  text-align: center;
  letter-spacing: 1px;
  margin: 0;
  color: rgb(220, 120, 0);
}
.content-wrapper h2 {
  font-size: 2em;
}

.content-wrapper .text-shadow {
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
      1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.content-wrapper .text-overlay{
  line-height: 1;
	display: inline-block;
	position: relative;
	font-style: normal;
	font-size: 4em;
	line-height: 1em;
	letter-spacing: 0;
	text-transform: none;
}

.content-wrapper .ing {
  width: 30%;
  display: inline-block;
  vertical-align: top;
}
.content-wrapper .descr {
  width: 65%;
  padding-left: 5%;
  display: inline-block;
  vertical-align: top;
}
#checklist {
  --background: transparent;
  --text: #fff;
  --check: #DC7800;
  --disabled: #C3C8DE;
  --width: 100%;
  --height: fit-content;
  --border-radius: 10px;
  background: var(--background);
  width: var(--width);
  height: var(--height);
  position: relative;
  padding: 30px 20px;
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  line-height: 1.5;
}
#checklist label {
  color: var(--text);
  position: relative;
  cursor: pointer;
  display: grid;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: color 0.3s ease;
}
#checklist label::before, #checklist label::after {
  content: "";
  position: absolute;
}
#checklist label::before {
  height: 2px;
  width: 8px;
  left: -27px;
  background: var(--check);
  border-radius: 2px;
  transition: background 0.3s ease;
}
#checklist label:after {
  height: 4px;
  width: 4px;
  top: 8px;
  left: -25px;
  border-radius: 50%;
}
#checklist input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  height: 15px;
  width: 15px;
  outline: none;
  border: 0;
  margin: 0 15px 0 0;
  cursor: pointer;
  background: var(--background);
  display: grid;
  align-items: center;
}
#checklist input[type=checkbox]::before, #checklist input[type=checkbox]::after {
  content: "";
  position: absolute;
  height: 2px;
  top: auto;
  background: var(--check);
  border-radius: 2px;
}
#checklist input[type=checkbox]::before {
  width: 0px;
  right: 60%;
  transform-origin: right bottom;
}
#checklist input[type=checkbox]::after {
  width: 0px;
  left: 40%;
  transform-origin: left bottom;
}
#checklist input[type=checkbox]:checked::before {
  animation: check-01 0.4s ease forwards;
}
#checklist input[type=checkbox]:checked::after {
  animation: check-02 0.4s ease forwards;
}
#checklist input[type=checkbox]:checked + label {
  color: var(--disabled);
  animation: move 0.3s ease 0.1s forwards;
}
#checklist input[type=checkbox]:checked + label::before {
  background: var(--disabled);
  animation: slice 0.4s ease forwards;
}
#checklist input[type=checkbox]:checked + label::after {
  animation: firework 0.5s ease forwards 0.1s;
}


.main-list > * {
  width: 100%;
  padding: 15px 30px;
}
.main-list > *:last-child, .main-list > *:first-child {
  padding-bottom: 30px;
}

.main-list h1 {
  font-weight: bold;
  border-top: 2px solid rgba(255, 255, 255, 0.533);
}

.main-list {
  margin: 0;
  padding: 0;
}

.main-list h2 {
  position: relative;
  color: #fff;
  text-align: left;
  margin-left: 3rem;
}

.main-list h2:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 20px;
    width: 7px;
    height: 14px;
    border-width: 0 3px 9px 0;
    border-style: solid;
    border-color: rgb(220, 120, 0);
    transform-origin: bottom left;
    transform: rotate(45deg);
}

@keyframes move {
  50% {
    padding-left: 8px;
    padding-right: 0px;
  }
  100% {
    padding-right: 4px;
  }
}
@keyframes slice {
  60% {
    width: 100%;
    left: 4px;
  }
  100% {
    width: 100%;
    left: -2px;
    padding-left: 0;
  }
}
@keyframes check-01 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(45deg);
  }
  100% {
    width: 5px;
    top: 8px;
    transform: rotate(45deg);
  }
}
@keyframes check-02 {
  0% {
    width: 4px;
    top: auto;
    transform: rotate(0);
  }
  50% {
    width: 0px;
    top: auto;
    transform: rotate(0);
  }
  51% {
    width: 0px;
    top: 8px;
    transform: rotate(-45deg);
  }
  100% {
    width: 10px;
    top: 8px;
    transform: rotate(-45deg);
  }
}
@keyframes firework {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check), 0 0 0 -2px var(--check);
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    box-shadow: 0 -15px 0 0px var(--check), 14px -8px 0 0px var(--check), 14px 8px 0 0px var(--check), 0 15px 0 0px var(--check), -14px 8px 0 0px var(--check), -14px -8px 0 0px var(--check);
  }
}

.detail-container-media {
  background-color: #242629;
  background-position: 50% 50%;
  background-size: cover;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 40%;
}
.detail-container {
  float: right;
  position: relative;
  width: 60%;
  height: 100vw;
  padding-top: 80px;
}

@media only screen and (max-width: 1000px) {
  .content-wrapper {
    width: 95%;
    padding: 1em 2.5%;
  }
  .content-wrapper .ing {
    display: block !important;
    width: 100%;
  }
  .content-wrapper .descr {
    padding: 0px;
    display: block !important;
    width: 100%;
  }
  .content-wrapper .descr > * {
    padding-left: 5%;
  }
  .content-wrapper .text-overlay{
    font-size: 2em;
  }
  .main-list {
    display: none !important;
  }
  .main-list > div > a{
    font-size: 1em;
  }
  .detail-container-media {
    position: relative;
    top: 0;
    width: 100%;
  }
  .detail-container {
    width: 100%;
    padding-top: 0;
  }
}

body.light .content-wrapper h1, body.light .content-wrapper h2 {
  color: rgb(0, 105, 137);
}

body.light .main-list h2 {
  color: dimgray;
}

body.light .main-list h2:before {
  border-color: rgb(0, 105, 137);
}

body.light #checklist {
  --text: dimgray;
  --check: rgb(0, 105, 137);
  --disabled: #C3C8DE;
}
.highlight-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  height: 40%;
}
.highlight-container-badges {
  display: block;
  height: 30px;
  position: relative;
  top: -15px;
  font-size: 15px;
  background: rgb(220, 120, 0);
  min-width: 50%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 10px;
  left: 10px;
}

svg {
  width: 100%;
}

.highlight-container h2 {
  font-size: 30px;
  padding: 0px;
  margin: 10px 30px;
}

.highlight-container-tag-cloud span.tag-cloud-tag {
  padding: 0;
  margin: 0 2px !important;
  border: none;
}

.highlight-container-tag-cloud {
  margin: 0 12px;
  margin-top: -17px;
}

.highlight-container-preps {
  padding: 0px 15px;
}

.highlight-container-date {
  font-size: 12px;
  font-weight: 100;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 8px;
}
#recipeInfo {
  margin-bottom: 10px;
}
.highlight-container-prep-title a {
  padding-left: 17px;
}
.highlight-container-prep-title a:before {
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 6px;
  border-width: 0 3px 9px 0;
  border-style: solid;
  border-color: rgb(220, 120, 0);
  transform-origin: bottom left;
  transform: rotate(45deg);
}
body.light .highlight-container {
  color: #fff;
}

body.light .highlight-container-badges {
  background: #006889;
}
body.light .highlight-container-prep-title a:before {
  border-color: rgb(0, 105, 137);
}

