.light div .summary{
  color: dimgray;
}
div .summary{
  color: #fff;
}
.search-results {
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 5;
  transition: margin-left .5s; /* If you want a transition effect */
  top: 40px;
  position: relative;
  width: 1000px;
  left: 50px;
  padding-bottom: 20px;
}

.search-results .search-result:first-child{
  border-radius: 5px 5px 0 0;
}

.search-results .search-result:last-child{
  border-radius: 0 0 5px 5px;
}

.search-result {
  margin-bottom: 1px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background: hsla(0,0%,100%,.9);
  color: #17181C;
}

.search-result > div{
  margin: 0;
  padding: 0;
}

.search-result > div.search-result-link {
  display: flex;
}

.search-result > div.search-result-content {
  font-family: "Bree Serif",serif;
  padding-left: 5px;
  padding-top: 10px;
}

.search-result img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 25px;
}

.search-result a {
  text-decoration: none;
}

.search-result a:hover {
  color: #006621;
}

.search-result h1,.search-result  h2, .search-result h3 {
  display: block;
  position: relative;
  font-size: calc(13px*var(--expansion-factor));
  margin: 0;
  color: #344055;
  font-family: "Bebas Neue",cursive;
  line-height: 1.1;
}

.search-result h1 {
  vertical-align: top;
  font-size: 30px;
}

.search-result h2 {
  font-size: 17px;
}

.search-result ul.search-result-ingredients {
  display: flex;
  list-style-position: inside;
  margin: 0; 
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-result ul.search-result-ingredients li {
  padding: 2px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}
.search-result ul.search-result-ingredients li.highlight-ing {
  color: red;
  font-weight: 800;
}

.search-result ul.search-result-ingredients li::marker {
  content: "- ";
}

.search-result ul.search-result-ingredients li:nth-child(3n)::marker {
  content: "- ";
}

.search-result ul.search-result-ingredients li:nth-child(3n - 1)::marker {
  content:"- ";
}
.search-result ul.search-result-ingredients li.highlight-ing::marker {
  color: red;
  font-weight: 800;
}
.search-result-a {
  vertical-align: top;
  display: block;
}

@media only screen and (max-width: 1000px) {
  .search-results {
    top: 40px;
    left: -1px;
    width: 900px;
    font-size: 40px;
  }
  .search-result-a {
    font-size: 15px;
  }
  .search-result img {
    width: 90px;
    height: 90px;
    margin-right: 10px;
    border-radius: 45px;
  }
  .search-result h2 {
    font-size: 34px;
  }
  .search-result h1 {
    font-size: 60px;
  }

}