@font-face {
  font-family: Black-Rose;
  src: url(../font/BLACKR.ttf);
}
:root {
  --card-height: 400px;
  --card-width: calc(var(--card-height) / 1.5);
}
* {
  box-sizing: border-box;
}
.card-home-container {
/*  width: 100vw;*/
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.card-home {
  width: var(--card-width);
  height: var(--card-height);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 36px;
  perspective: 2500px;
  margin: 10px 50px;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  padding-bottom: 60px;
  border-radius: 4px;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.card-home:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 130px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 97%
  );
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 46%,
    rgba(12, 13, 19, 0.5) 68%,
    rgba(12, 13, 19) 127%
  );
}

.card-home:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.card-home:hover .wrapper::after {
  height: 120px;
  opacity: 0;
}
.card-home .title {
  width: 100%;
  transition: transform 0.5s;
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
    1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
  font-size: 3em;
  font-family: Black-Rose;
  color: darkred;
  margin: 11px 0;
}
.card-home:hover .title {
  transform: translate3d(0%, -50px, 100px);
  z-index: 0;
}

.character {
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
  cursor: pointer;
}

.card-home:hover .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}
body.light .card-home .title {
  color: #3185a0;
}

#cardCategories {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%
}
@media only screen and (max-width: 1000px) {
  .character {
    opacity: 1;
  }
}