body.light .highlight-container {
  color: #fff;
}

body.light .highlight-container-badges {
  background: #006889;
}
body.light .highlight-container-prep-title a:before {
  border-color: rgb(0, 105, 137);
}
