/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#sidebarContent .button {
  position: relative;
  top: 12px;
  z-index: 5;
  color: white;
  padding: 2px;
  font-size: 30px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.90);
  border-radius: 0.25em 0 0 0.25em;
  display: inline-block;
  width: 42px;
}
#sidebarContent .button svg {
  margin: auto;
  display: block;
  width: 90%;
  height: 90%;
  margin-top: 2px;
}

#sidebarContent {
  font-size: 19px;
  z-index: 5;
  transition: margin-left .5s; /* If you want a transition effect */
  position: relative;
  width: 100%;
  padding: 0 10% 0 10%;
}
.sticky {
  display: unset;
  position: fixed !important;
  top: 0;
  left: 0;
  padding-bottom: 20px !important;
  padding-left: calc(10% + 10px) !important;
  padding-right: calc(10% + 50px) !important;
  box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.75);
  background: url(images/image.png) no-repeat left;
  background-position-x: 3%;
  background-position-y: 0;
  background-color: rgb(0, 0, 0, 0.4);
  background-size: contain;
  animation: showBG 1s none;
}
/* Style the navigation menu */
#sidebarContent .topnav {
  cursor: text;
  color: #17181c;
  background-color: rgba(255, 255, 255, 0.90);
	border-radius: 0 .25em .25em 0;
	box-shadow: none;
  display: inline-block;
	padding: 9.5px;
	transition-delay: calc(var(--dur) * 0.25);
	width: calc(100% - 55px);
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  position: relative;
  height: 40px;
  padding-left: 0;
}
.search-bar {
  font-size: 16px;
  border: none;
  background: none;
  outline: none;
  width: calc(100% - 90px);
}
.search-bar:hover{
  border: none;
}
.search-bar:visited{
  border: none;
}
.search-bar:focus { 
  border:none; 
  font-size: 20px;
  transition: 1s;
}
  /* Position and style the close button (top right corner) */
  .closebtn {
    display: block;
    float: left;
    cursor: pointer;
    padding: 0 3px 3px 3px;
    font-size: 20px;
    border-radius: 20%;
    caret-color: var(--primary);
    width: 30px;
    height: 30px;
    appearance: none;
    color: #17181c;
    text-align: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  }
  raga-switch-theme {
    position: relative;
    top: -7px;
    float: right;
  }

  @media only screen and (max-width: 1000px) {
    div .sidebar {padding-top: 15px;}
    div .sidebar a {font-size: 18px;}
    #sidebarContent {
      padding: 0 7% 0 19%;
      font-size: 40px;
    }
    .sticky {
      padding-left: calc(17% + 10px) !important;
      padding-right: calc(9% + 50px) !important;
    }
    #sidebarContent .button {
      width: 59px;
      top: 7px;
    }
    #sidebarContent .topnav {
      left: 0;
      width: calc(100% - 89px);
      height: 55px;
    }
    #sidebarContent .fa.fa-search {
      font-size: 33px;
    }
    .search-bar {
      font-size: 33px;
      margin: auto;
      position: relative;
      top: -10px;
    }
    .search-bar:focus { 
      font-size: 35px;
    }
    raga-switch-theme {
      top: -25px;
    }
  }
  
  @keyframes showBG {
    from {
      background-color: transparent;
    }
    to {
      background-color: rgb(0, 0, 0, 0.4);
    }
    from {
      background-position-y: -100px;
    }
    to {
      background-position-y: 0;
    }
  }
