.root-normalize-container, #themeSelector {
  --primary-color: #219EBCDE;
  --secondary-color: #023047;
  --text-color: #FFFFFF;
  --accent-primary-color: #FFB703;
  --accent-secondary-color: #FFDABC;
  --accent-text-color: #000000;
  --error-color: #FB8500;
}
.root-normalize-container.cz, #themeSelector.cz {
  --primary-color: #F5DC40DE;
  --secondary-color: #F5DC40;
  --text-color: #C30613;
  --accent-primary-color: #C30613;
  --accent-secondary-color: #C30613AA;
  --accent-text-color: #F5DC40;
  --error-color: #C30613;
}
.root-normalize-container.first, #themeSelector.first {
  --primary-color: #264653DE;
  --secondary-color: #2A9D8F;
  --text-color: #FFFFFF;
  --accent-primary-color: #F4A261;
  --accent-secondary-color: #FFDABC;
  --accent-text-color: #000000;
  --error-color: #FF0000;
}
.root-normalize-container.second, #themeSelector.second {
  --primary-color: #780000DE;
  --secondary-color: #C1121F;
  --text-color: #FDF0D5;
  --accent-primary-color: #003049;
  --accent-secondary-color: #669BBC;
  --accent-text-color: #FFFFFF;
  --error-color: #C1121F;
}
.root-normalize-container.xmas, #themeSelector.xmas {
  --primary-color: #2A7539DE;
  --secondary-color: #64AC3A;
  --text-color: #F5E9CC;
  --accent-primary-color: #CE3639;
  --accent-secondary-color: #ED9D82;
  --accent-text-color: #F5E9CC;
  --error-color: #CE3639;
}
#themeSelector {
  width: 170px;
  position: absolute;
  top: 50px;
  right: 100px;
  z-index: 1000;
}
#themeSelector label {
  font-size: 20px;
}
